// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState } from "framer";

const cycleOrder = ["RnxB82rKM", "Z62l_MlXk"];

const variantClassNames = {RnxB82rKM: "framer-v-8mjr1s", Z62l_MlXk: "framer-v-yi4of6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Burger: "RnxB82rKM", X: "Z62l_MlXk"};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 5, delay: 0, stiffness: 400, damping: 30, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "RnxB82rKM", tap: TBgHLr7MW, color: iWF8EWIN1 = "rgb(136, 136, 136)", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "RnxB82rKM", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant);

const onTap8mjr1s = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("Z62l_MlXk")
})

const onTapyi4of6 = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("RnxB82rKM")
})

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-TnibG", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-8mjr1s", className)} style={{...style}} layoutId={"RnxB82rKM"} transition={transition} layoutDependency={layoutDependency} background={null} data-highlight={true} data-framer-name={"Burger"} onTap={onTap8mjr1s} ref={ref} {...addPropertyOverrides({Z62l_MlXk: {onTap: onTapyi4of6, "data-framer-name": "X", "data-highlight": true}}, baseVariant, gestureVariant)}><motion.div className={"framer-18x83ra"} style={{}} layoutId={"B3S5IxCDb"} transition={transition} layoutDependency={layoutDependency} background={null}><motion.div className={"framer-123g2n"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} layoutId={"itbZCRpxH"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Bottom"} variants={{Z62l_MlXk: {rotate: -45}}} {...addPropertyOverrides({Z62l_MlXk: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-13d4wtf"} style={{backgroundColor: iWF8EWIN1, rotate: 0, opacity: 1}} layoutId={"vxdOiu2Za"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Mid"} variants={{Z62l_MlXk: {rotate: 0, opacity: 0}}} {...addPropertyOverrides({Z62l_MlXk: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-j5mxfj"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} layoutId={"PiAPrrhZQ"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Top"} variants={{Z62l_MlXk: {rotate: 45}}} {...addPropertyOverrides({Z62l_MlXk: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-TnibG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TnibG * { box-sizing: border-box; }", ".framer-TnibG .framer-8mjr1s { position: relative; cursor: pointer; overflow: hidden; width: 32px; height: 32px; }", ".framer-TnibG .framer-18x83ra { position: absolute; overflow: visible; width: 24px; height: 18px; left: calc(50.00000000000002% - 24px / 2); top: calc(50.00000000000002% - 18px / 2); flex: none; }", ".framer-TnibG .framer-123g2n { position: absolute; overflow: hidden; height: 2px; right: 0px; bottom: 0px; left: 0px; flex: none; }", ".framer-TnibG .framer-13d4wtf { position: absolute; overflow: hidden; height: 2px; right: 0px; left: 0px; top: calc(50.00000000000002% - 2px / 2); flex: none; }", ".framer-TnibG .framer-j5mxfj { position: absolute; overflow: hidden; height: 2px; right: 0px; left: 0px; top: 0px; flex: none; }", ".framer-TnibG.framer-v-yi4of6 .framer-8mjr1s { cursor: pointer; }", ".framer-TnibG.framer-v-yi4of6 .framer-123g2n { width: auto; height: 2px; right: 0px; bottom: auto; left: 0px; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }", ".framer-TnibG.framer-v-yi4of6 .framer-13d4wtf { width: 2px; height: 2px; right: 0px; bottom: auto; left: auto; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }", ".framer-TnibG.framer-v-yi4of6 .framer-j5mxfj { width: 26px; height: 2px; right: -1px; bottom: auto; left: auto; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Z62l_MlXk":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const FramerBInUCqY8T: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerBInUCqY8T;

FramerBInUCqY8T.displayName = "Elements/Menu Icon Copy 2";

FramerBInUCqY8T.defaultProps = {width: 32, height: 32};

addPropertyControls(FramerBInUCqY8T, {variant: {type: ControlType.Enum, title: "Variant", options: ["RnxB82rKM", "Z62l_MlXk"], optionTitles: ["Burger", "X"]}, TBgHLr7MW: {type: ControlType.EventHandler, title: "Tap"}, iWF8EWIN1: {type: ControlType.Color, title: "Color", defaultValue: "rgb(136, 136, 136)"}} as any);

addFonts(FramerBInUCqY8T, []);